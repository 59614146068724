'use strict';

var config = {
  bp : {
    xs: 480,
    sm: 768,
    md: 980,
    lg: 1200
  }
};

module.exports = config;