'use strict';

module.exports = function() {};

module.exports.prototype = {
  extend: function(properties) {
    var Child = module.exports;
    Child.prototype = module.exports.prototype;
    for(var key in properties) {
      Child.prototype[key] = properties[key];
    }
    return Child;
  },
  toggleClass: function( t, s ) {
    s = s || 'open';
    t.toggleClass(s);
  },
  addClass: function( t, s ) {
    s = s || 'open';
    t.addClass(s);
  },
  addCss: function( o, css ) {
    o.css(css);
  },
  removeClass: function( t, s ) {
    s = s || 'open';
    t.removeClass(s);
  },
  setHeight: function( o, h, event ) {
    o = o || event.data.o;
    h = h || event.data.h;
    o.height(h);
  }
}